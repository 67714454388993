import React,{ useState } from 'react';
import { Form } from 'react-bootstrap';
import "./emailInputField.css";

const EmailInputField = ({
  label,
  name,
  required,
  value,
  type,
  onChange,
  placeholder,
  feedbackMessage,
  invalidEmailMessage,
  colSize,
  validationRegex,
  validationErrorMessage,
  validated,
  maxLength,
}) => {
  const [error, setError] = useState(null);

  // const isValidEmail = (email) => {
  //   const emailRegex = /^[a-zA-Z0-9.!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{1,50}$/;
  //   return emailRegex.test(email);
  //   // return emailRegex.test(email) && email.length <= 50;
  //   // return emailRegex.test(input) && input.length <= 50;
  // };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // onChange({ target: { name, value: inputValue } });
    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }

    const isValidInput = validationRegex.test(inputValue);

    if (isValidInput) {
      setError(null);
      onChange({ target: { name, value: inputValue } });
    } else {
      setError(
        validationErrorMessage
      );
    }
  };
 

  // const emailValid = isValidEmail(value);
  // const isEmpty = !value || value.trim() === '';
  // const isLengthExceeded = value.length > 50;

  return (
    <Form.Group >
      {/* {label && <Form.Label className="label-style">{label}</Form.Label>} */}
      <Form.Control
        type={type}
        name={name}
        className="readOnlyDate_field"
        placeholder={placeholder}
        required={required}
        value={value}
        // isInvalid={validated && (!emailValid || isEmpty)}
        onChange={handleInputChange}
        maxLength={maxLength}
        isInvalid={!!error}
      />
      {/* {validated && feedbackMessage && (
        <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
          {isEmpty
            ? 'Please enter a Email or Phone number.'
            : !emailValid && 'Please enter a valid input with a maximum length of 50 characters.'}
        </Form.Control.Feedback>
      )} */}

<Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {error ||
          (validated && !validationRegex.test(value) && error) ||
          (validated && value.length <= 0 && feedbackMessage)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default EmailInputField;
