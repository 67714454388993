import React, { useState } from "react";
import "./forgotPage.css"; // Importing the styles
import { Col } from "react-bootstrap";
import { BASE_URL } from "./apiConfig";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setIsValid(e.target.value.trim() !== ""); // Simple validation for empty input
        setErrorMessage(""); // Clear error message on email change
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isValid) return;

        setIsLoading(true); // Show a loading indicator while the API is called

        const requestBody = {
            username: email,
            // username: "revanthkumar@tminetwork.com", // Add the username
            domain: "https://admin.clanonline.in" // Add the domain
        };

        try {
            const response = await fetch("https://clanapilightsail.clanonline.in/clantestapi/forget_password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody), // Send the updated request body
            });

            if (response.ok) {
                // If the response is successful, navigate to the "Check Email" page
                const data = await response.json();
                setSuccessMessage(data.details); // Show the success message from the response
                setErrorMessage(""); //

            } else if (response.status === 404) {
                // Handle error for unregistered email
                setErrorMessage("This email is not registered. Please try again.");
                setSuccessMessage(""); // Clear success message
                // Handle other server errors
            }
            else {
                const errorData = await response.json();
                const errorDetail = errorData.detail?.detail || "Something went wrong. Please try again."; // Accessing the nested error message
                setErrorMessage(errorDetail); // Set the error message from response
                setSuccessMessage("");
            }
        } catch (error) {
            console.error("Error during password reset request:", error);
            setErrorMessage("Unable to process your request. Please try again.");
            setSuccessMessage("");
        } finally {
            setIsLoading(false); // Hide the loading indicator
        }
    };

    return (
        <div className="reset-password-container">
            <div className="reset-password-form">
                <h2 className="reset-password-title">Reset your password!</h2>
                <p className="reset-password-subtitle">
                    Enter your email, and we'll help you reset it in a snap
                </p>
                <p className="security-made-simple">Security made simple</p>
                <form onSubmit={handleSubmit}>
                    <label className="email-label">Email</label>
                    <div className="email-field">
                        <Col sm={6} xs={12} md={6} lg={3}>
                            <input
                                type="email"
                                className="email-input"
                                placeholder="example@gmail.com"
                                value={email}
                                onChange={handleEmailChange}
                                required
                            />
                        </Col>
                    </div>
                    {errorMessage && (
                        <p className="error-message" style={{ color: "red", marginTop: "10px" }}>
                            {errorMessage}
                        </p>
                    )}
                    {successMessage && (
                        <p className="success-message" style={{ color: "green", marginTop: "10px" }}>
                            {successMessage}
                        </p>
                    )}
                    <button
                        type="submit"
                        className={`continue-btn ${isValid && !isLoading ? "active" : "inactive"}`}
                        disabled={!isValid || isLoading}
                    >
                        {isLoading ? "Processing..." : "Continue"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
