import React, { useState, useEffect } from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { currentUserProfileQuery } from "../services/userAPI";
import "./resuableHeader.css";
import Icon2 from "../Images/icon2.png";
import { LogoutOutlined } from "@ant-design/icons";
import { IoIosLogOut } from "react-icons/io";

const ReusableHeader = ({ setIsAuthenticated = () => { } }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [userData, setUserData] = useState(null);

    const { loading, error, data } = useQuery(currentUserProfileQuery, {
        skip: !!userData,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (data?.currentUserProfile) {
            setUserData(data.currentUserProfile);
        }
    }, [data]);

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const logout = () => {
        localStorage.removeItem("flutter.accessToken");
        setIsAuthenticated(false);
        setUserData(null);
        window.location.href = "/login-page";
    };

    if (loading && !userData) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <header className="header-container">
            <div className="logo-container">
                <img src={Icon2} alt="Logo" />
            </div>

            {userData && (
                <div className="dropdown">
                    {/* Dropdown Toggle */}
                    <div className="dropdown-toggle" onClick={toggleDropdown}>
                        {isDropdownOpen ? (
                            <ExpandLess style={{ color: "#FFFFFF" }} /> // Up arrow
                        ) : (
                            <ExpandMore style={{ color: "#FFFFFF" }} /> // Down arrow
                        )}
                        {/* <img
                            src={ExpandCircleDown} /> */}
                        <span>{userData.name}</span> {/* Always above dropdown menu */}
                        <img
                            src={userData.profilePicture || "default-profile-image.png"}
                            alt="User"
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                border: "2px solid #FFFFFF",
                            }}
                        />
                    </div>

                    {/* Dropdown Menu */}
                    <div className={`dropdown-menu ${isDropdownOpen ? "open" : ""}`}>
                        <button onClick={logout} className="logout-button">
                            <span className="logout-text">Logout</span>
                            <IoIosLogOut className="logout-icon" />
                        </button>
                    </div>
                </div>

            )}
        </header>
    );
};

export default ReusableHeader;
