import React, { createContext, useState, useContext } from "react";

// Create a context for breadcrumb
const BreadcrumbContext = createContext();

// Breadcrumb provider to wrap around your app
export const BreadcrumbProvider = ({ children }) => {
    const [breadcrumb, setBreadcrumb] = useState([]);

    // Function to reset breadcrumbs to the root
    const resetBreadcrumb = () => {
        setBreadcrumb([{ name: "Home", path: "/" }]);
    };

    // Function to append a new breadcrumb item
    const appendBreadcrumb = (newItem) => {
        setBreadcrumb((prev) => [...prev, newItem]);
    };

    return (
        <BreadcrumbContext.Provider value={{ breadcrumb, resetBreadcrumb, appendBreadcrumb }}>
            {children}
        </BreadcrumbContext.Provider>
    );
};

// Custom hook to use breadcrumb context
export const useBreadcrumb = () => {
    const context = useContext(BreadcrumbContext);
    if (!context) {
        throw new Error("useBreadcrumb must be used within a BreadcrumbProvider");
    }
    return context;
};
