import React, { useState, useEffect } from "react";
import { Form, Button, message, Layout, Row, Col, Upload, Checkbox, Card } from "antd";
import { useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import "./createClientForm.css";
import TextInputField from "../reusableField/TextInputField2";
import { validationRegexAddress, validationRegexOnlyAlpha } from "../regex/regex";
import Arrow from "../Images/arrow_circle_right.png";
import Vector from "../Images/Vector.png"; // Default upload icon
import { Account_Form_Query, Role_Account_Query, Get_Lense_Account_Projects } from "../services/userAPI"; // Import your mutation
import { Typography, Box, Paper, FormControlLabel } from '@mui/material';

const { Content } = Layout;

const CreateClientForm = () => {
    const { id } = useParams();
    const location = useLocation();
    const accountId = id || location.state?.id;
    const { name, address, imageUrl, role, projectsData: passedProjectsData } = location.state || {};

    const [formData, setFormData] = useState({
        id: location.state?.id || "",
        name: location.state?.name || "",
        imageUrl: location.state?.imageUrl || "",
        companyURL: "",
        address: location.state?.address || "",
        role: location.state?.role || "",
        roles: [],
        emailLogin: false, // State for "Email Login"
        numberLogin: false, // State for "Number Login"
    });


    // Use Apollo Client's useQuery hook for getting roles
    const { data: rolesData, loading: rolesLoading, error: rolesError } = useQuery(Role_Account_Query, {
        variables: { accountId: parseInt(accountId) },  // Pass accountId as a variable
        skip: !accountId,  // Skip the query if id is not available
    });

    console.log('Query Variables:', { clientId: accountId ? String(accountId) : "" });
    useEffect(() => {
        if (rolesError) {
            message.error(`Failed to load roles: ${rolesError.message}`);
        }
    }, [rolesError]);
    const [accountIdReady, setAccountIdReady] = useState(false);
    useEffect(() => {
        if (accountId) {
            setAccountIdReady(true);
        }
    }, [accountId]);

    console.log(accountIdReady);


    const [clientImage, setClientImage] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (formData.name !== undefined && formData.address !== undefined) {
            const isValid = Object.values(formData).every((field) =>
                typeof field === "string" ? field.trim() !== "" : true
            ) && (clientImage || formData.imageUrl);
            setIsFormValid(isValid);
        }
    }, [formData, clientImage]);

    useEffect(() => {
        const isValid = Object.values(formData).every((field) => {
            if (typeof field === 'string') {
                return field.trim() !== "";
            }
            return true; // For non-string fields, we just pass them (booleans, etc.)
        }) && (clientImage || formData.imageUrl); // Image validation
        setIsFormValid(isValid);
    }, [formData, clientImage]);


    const [addAccount, { error }] = useMutation(Account_Form_Query, {
        onCompleted: (data) => {
            message.success("Client created successfully!");
        },
        onError: (error) => {
            message.error("Failed to create client: " + error.message);
        },
    });

    const handleImageChange = ({ file }) => {
        const isImage = file.type.startsWith("image/");
        if (isImage) {
            setClientImage(file.originFileObj);
        } else {
            message.error("Only image files are allowed!");
        }
    };

    const { data, loading } = useQuery(Get_Lense_Account_Projects, {
        variables: { clientId: accountId ? String(accountId) : "" }, // Pass the clientId dynamically
        skip: !accountIdReady, // Skip query execution if accountIdd is not set
    });

    const projectsData = passedProjectsData || data?.getLenseAccountProjects;



    const handleInputChange = ({ target: { name, value } }) => {
        if (name === "role.name") {
            setFormData((prevData) => ({
                ...prevData,
                role: value, // Update the role state
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [role.name]: value,
            }));
        }
    };


    const handleEmailLoginChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            emailLoginChecked: e.target.checked,
        }));
    };

    const handleNumberLoginChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            numberLoginChecked: e.target.checked,
        }));
    };

    const onFinish = () => {
        addAccount({
            variables: {
                name: formData.name,
                address: formData.address,
                url: formData.companyURL,
                smallLogo: formData.imageUrl,
                mainLogo: formData.imageUrl,
                emailLogin: formData.emailLoginChecked,
                numberLogin: formData.numberLoginChecked,
                role: formData.role,
                roles: formData.roles,
            },
        });
    };

    const handleRoleCheckboxChange = (roleId, field, value) => {
        setFormData((prevData) => {
            const updatedRoles = prevData.roles.map((role) =>
                role.id === roleId ? { ...role, [field]: value } : role
            );
            return {
                ...prevData,
                roles: updatedRoles,
            };
        });
    };



    const handleRoleChange = (roleId) => (e) => {
        const updatedRoles = formData.roles.map((role) =>
            role.id === roleId ? { ...role, name: e.target.value } : role
        );
        setFormData((prevData) => ({
            ...prevData,
            roles: updatedRoles,
        }));
    };


    useEffect(() => {
        if (rolesData && rolesData.getAllJobrole) {
            setFormData((prevData) => ({
                ...prevData,
                roles: rolesData.getAllJobrole.map((role) => ({
                    id: role.id,
                    name: role.name || "", // Initialize name or set to empty
                    clanNeeded: role.clanNeeded || false,
                    dartsNeeded: role.dartsNeeded || false,
                    dartsFormNeeded: role.dartsFormNeeded || false,
                })),
            }));
        }
    }, [rolesData]);


    return (
        <Layout>
            <Content style={{ margin: "16px", marginTop: "0px" }}>
                <div className="create-client-container">
                    <h2>{id ? "Edit Client" : "Create New Client"}</h2>
                    <Form layout="vertical" className="create-client-form">
                        <Form.Item name="clientImage">
                            <div className="upload-container">
                                <Upload
                                    name="clientImage"
                                    showUploadList={false}
                                    customRequest={handleImageChange} // Pass image upload handler
                                >
                                    <div className="image-upload-circle">
                                        {clientImage ? (
                                            <img
                                                src={URL.createObjectURL(clientImage)} // Display uploaded image
                                                alt="Uploaded"
                                                className="uploaded-image"
                                            />
                                        ) : formData.imageUrl ? (
                                            <img
                                                src={formData.imageUrl} // Display existing image URL
                                                alt="Existing Client"
                                                className="uploaded-image"
                                            />
                                        ) : (
                                            <img
                                                src={Vector} // Display default upload icon
                                                alt="Upload Icon"
                                                className="upload-icon"
                                            />
                                        )}
                                    </div>
                                </Upload>
                            </div>
                        </Form.Item>

                        <Row>
                            <Col sm={12} xs={12} md={6} lg={6} style={{ marginBottom: "2%", marginRight: "2%" }}>
                                <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Client Name</label>
                                <TextInputField
                                    name="name"
                                    value={formData.name}
                                    placeholder="Enter Client Name"
                                    feedbackMessage="Please input the client name."
                                    validationRegex={validationRegexOnlyAlpha}
                                    onChange={handleInputChange}
                                />
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} style={{ marginBottom: "2%", marginRight: "2%" }}>
                                <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Company URL</label>
                                <TextInputField
                                    name="companyURL"
                                    value={formData.companyURL}
                                    placeholder="Enter Company URL"
                                    feedbackMessage="Please input the company URL."
                                    validationRegex={validationRegexOnlyAlpha}
                                    onChange={handleInputChange}
                                />
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} style={{ marginBottom: "2%", marginRight: "2%" }}>
                                <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Client Office Address</label>
                                <TextInputField
                                    name="address"
                                    value={formData.address}
                                    placeholder="Address"
                                    feedbackMessage="Please input the address."
                                    validationRegex={validationRegexAddress}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>

                        {/* Two checkboxes inline */}
                        <Row>
                            <Col span={4}>
                                <Form.Item>
                                    <Checkbox
                                        checked={formData.emailLoginChecked}
                                        onChange={handleEmailLoginChange}
                                    >
                                        Email Login
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item>
                                    <Checkbox
                                        checked={formData.numberLoginChecked}
                                        onChange={handleNumberLoginChange}
                                    >
                                        Number Login
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className="create-client-button-container">
                            <Button
                                type="primary"
                                onClick={onFinish}
                                disabled={!isFormValid || loading}
                            >
                                <img src={Arrow} alt="Arrow Icon" className="arrow-circle" />
                                {id ? "Update Client" : "Create Client"}
                            </Button>
                        </div>

                        <Paper sx={{ padding: "20px", marginTop: "20px", borderRadius: 2, boxShadow: 3 }}>
                            <Box>
                                {rolesLoading ? (
                                    <Typography variant="body2">Loading job roles...</Typography>
                                ) : formData.roles && formData.roles.length > 0 ? (
                                    formData.roles.map((role, index) => (
                                        <TextInputField
                                            key={role.id}
                                            name={`role-${role.id}`} // Unique identifier for each role
                                            value={role.name}
                                            placeholder="Enter Role Name"
                                            feedbackMessage="Please input the role name."
                                            onChange={(e) =>
                                                handleRoleCheckboxChange(role.id, "name", e.target.value)
                                            }
                                        />
                                    ))
                                ) : (
                                    <Typography variant="body2">No job roles available.</Typography>
                                )}




                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Roles:</Typography>
                                {rolesLoading ? (
                                    <Typography variant="body2">Loading job roles...</Typography>
                                ) : rolesData && rolesData.getAllJobrole && rolesData.getAllJobrole.length > 0 ? (
                                    rolesData.getAllJobrole.map((role) => (
                                        <Box key={role.id} sx={{ marginBottom: 2, padding: 1, border: '1px solid #ccc', borderRadius: 1 }}>

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={role && role.clanNeeded ? role.clanNeeded : false} // Safeguard for undefined role
                                                            onChange={() => handleInputChange('clanNeeded', role.id)}
                                                            disabled={role && role.clanNeeded}
                                                            name="clanNeeded"
                                                        />
                                                    }
                                                    label="Clan Needed"
                                                />

                                                {/* Darts Needed Checkbox */}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={role && role.dartsNeeded ? role.dartsNeeded : false} // Safeguard for undefined role
                                                            onChange={() => handleInputChange('dartsNeeded', role.id)}
                                                            disabled={role && role.dartsNeeded}
                                                            name="dartsNeeded"
                                                        />
                                                    }
                                                    label="Darts Needed"
                                                />

                                                {/* Darts Form Needed Checkbox */}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={role && role.dartsFormNeeded ? role.dartsFormNeeded : false} // Safeguard for undefined role
                                                            onChange={() => handleInputChange('dartsFormNeeded', role.id)}
                                                            disabled={role && role.dartsFormNeeded}
                                                            name="dartsFormNeeded"
                                                        />
                                                    }
                                                    label="Darts Form Needed"
                                                />
                                            </Box>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography variant="body2">No job roles available.</Typography>
                                )}
                            </Box>

                            {/* Client name input field */}
                            <TextInputField
                                name="role"
                                value={formData.role}
                                placeholder="Enter Client Name"
                                feedbackMessage="Please input the client name."
                                validationRegex={validationRegexOnlyAlpha}
                                onChange={handleInputChange}
                            />
                        </Paper>

                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '20px' }}>Client Projects</Typography>
                        {loading ? (
                            <Typography>Loading projects...</Typography>
                        ) : projectsData && projectsData.length > 0 ? (
                            projectsData.map((project) => (
                                // <div key={project.id}>
                                <h4>{project.name}</h4>
                                // <p>ID: {project.id}</p>
                                // </div>
                            ))
                        ) : (
                            <div>No projects available.</div>
                        )}
                    </Form>
                </div>
            </Content>
        </Layout >
    );
};

export default CreateClientForm;
