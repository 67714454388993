import React, { useState } from "react";
import "./LoginPage.css";
import illustration from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import { Col } from "react-bootstrap";
import EmailInputField from "../reusableField/EmailInputField";
import TextInputField from "../reusableField/TextInputField";
import { Link, useNavigate } from "react-router-dom";
import { emailRegex, passwordRegex } from "../regex/regex";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Accounts_Query } from "../services/userAPI";

const LoginPage = ({ setIsAuthenticated }) => {
    const [loginId, setLoginId] = useState("");
    const [password, setPassword] = useState("");
    const [session, setSession] = useState("");
    const [passwordEnabled, setPasswordEnabled] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const navigate = useNavigate();
    const appKey = "7cb80ffc8df6a5bf488e69f001ee0ab3";

    const validateEmail = async () => {
        setLoading(true);
        setMessage("");
        try {
            const response = await fetch("https://clanapilightsail.clanonline.in/clantestapi/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username: loginId, appkey: appKey }),
            });

            if (response.ok) {
                const data = await response.json();
                setSession(data.session); // Store session for the next step
                setPasswordEnabled(true); // Enable password field
                setMessage("Email validated. Please enter your password.");
            } else {
                const errorData = await response.json();
                setMessage(errorData.detail || "Failed to validate email.");
            }
        } catch (error) {
            setMessage("Error: Unable to validate email. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    // API call to log in with email, password, and session
    const handleLogin = async (event) => {
        event.preventDefault();
        setLoginLoading(true);
        setMessage("");
        try {
            const response = await fetch("https://clanapilightsail.clanonline.in/clantestapi/user_validate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username: loginId, password, session, appkey: appKey }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem("flutter.accessToken", data.access_token);
                setIsAuthenticated(true);
                navigate("/home");
            } else {
                const errorData = await response.json();
                setMessage(errorData.detail || "Login failed.");
            }
        } catch (error) {
            setMessage("Error: Unable to log in. Please try again.");
        } finally {
            setLoginLoading(false);
        }
    };

    return (
        <div className="login-container">
            <div className="wrapper-container">
                <div className="illustration-container">
                    <img src={illustration} alt="Illustration" className="illustration" />
                </div>
                <div className="form-container">
                    <img src={icon2} alt="icon2" className="icon2" />
                    <p className="welcome-text">Welcome</p>

                    <form className="login-form" onSubmit={handleLogin}>
                        {/* Email Field */}
                        <div className="form-group">
                            <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Login ID</label>
                            <Col sm={12} xs={12} md={12} lg={12} >
                                <EmailInputField
                                    name="Email"
                                    placeholder="Enter Login ID "
                                    type="email"
                                    value={loginId}
                                    onChange={(e) => setLoginId(e.target.value)}
                                    feedbackMessage="Please enter Email."
                                    validationRegex={emailRegex}
                                    validationErrorMessage="Please enter a valid Email."
                                />
                            </Col>
                        </div>

                        {/* Password or Validate Email Button */}
                        <div className="form-group">
                            <Col sm={12} xs={12} md={12} lg={12}>
                                {passwordEnabled ? (
                                    <div className="password-field-container">
                                        <label>Password</label>
                                        <TextInputField
                                            placeholder="Password"
                                            name="password"
                                            type={passwordVisible ? "text" : "password"}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            feedbackMessage="Please enter your password."
                                            validationRegex={passwordRegex}
                                            validationErrorMessage="Password is required."
                                        />
                                        <span
                                            className="eye-icon"
                                            onClick={() => setPasswordVisible(!passwordVisible)}  // Toggle password visibility
                                        >
                                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}  {/* Toggle eye icon */}
                                        </span>
                                    </div>


                                ) : (

                                    <button
                                        type="button"
                                        className="email-validate-btn"
                                        disabled={!loginId.trim() || loading}
                                        onClick={validateEmail}
                                    >
                                        {loading ? "Validating..." : "Validate Email"}
                                    </button>
                                )}
                            </Col>
                        </div>

                        {/* Submit Button */}
                        {passwordEnabled && (
                            <div className="submit-container">
                                <button
                                    type="submit"
                                    className="submit-btn"
                                    disabled={!password.trim() || loginLoading}
                                >
                                    {loginLoading ? "Logging in..." : "Submit"}
                                </button>
                            </div>
                        )}
                    </form>

                    {message && <p style={{ marginLeft: "28%" }} className="error-message">{message}</p>}

                    <Link to="/forgot-password" className="forgot-password">
                        Forgot Password?
                    </Link>
                </div>
            </div>
        </div >
    );
};

export default LoginPage;
