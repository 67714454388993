import React, { useState } from "react";
import { Layout, Input, Button, Form, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Accounts_Query } from "../services/userAPI"; // Import your GraphQL query
import "./Home.css"; // Ensure you have the correct styles for the cards
import CreateClientImage from "../Images/add_circle_outline.png"
import { useNavigate } from 'react-router-dom';
import "./createClient.css"
import { Get_Lense_Account } from "../services/userAPI";

const { Content } = Layout;

const CreateClient = () => {
    const [isCreating, setIsCreating] = useState(false); // State to toggle between card view and form
    const [clientImage, setClientImage] = useState(null);
    const navigate = useNavigate();

    // Fetch client data using Apollo
    const { loading, error, data } = useQuery(Accounts_Query);
    const [fetchLenseAccounts, { loading: lenseLoading, error: lenseError, data: lenseData }] = useLazyQuery(Get_Lense_Account);

    // Handle loading and error states
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    // Handle image upload
    const handleImageChange = (info) => {
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
            setClientImage(info.file.originFileObj);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    };



    const onFinish = (values) => {
        message.success("Client created successfully!");
        setIsCreating(false); // Go back to card view
    };

    const handleEdit = (id, name, address, imageUrl) => {
        console.log(`Edit account ${id}`);
        navigate(`/create-client-form?id=${id}`, {
            state: {id, name, address, imageUrl }

            // Pass the client data as state
        });
        console.log(name)
    }

    return (
        <Layout>
            <Content style={{ margin: "0 16px", marginTop: "0px" }}>
                <div className="create-client-container">
                    {!isCreating ? (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h2>Active Clients</h2>
                                <Button style={{ backgroundColor: "#4B31C3" }}
                                    type="primary"
                                    onClick={() => navigate("/import-from-lense")}
                                >
                                    Import From Lense
                                </Button>
                            </div>
                            <div className="cards-container">
                                {/* Render client cards */}
                                {data.allUserAccount.map((user) => (
                                    <div key={user.id} className="card">
                                        <img
                                            src={user.mainLogoUrl || "https://via.placeholder.com/80"}
                                            alt={user.name}
                                            className="card-image"
                                        />
                                        <h3 className="card-title">{user.name}</h3>
                                        <button
                                            onClick={() => handleEdit(user.id, user.name, user.address, user.imageUrl)}
                                            className="card-button">Edit</button>
                                    </div>
                                ))}
                                <div
                                    className="card create-new-client"
                                    onClick={() => navigate("/create-client-form")}
                                >
                                    <img
                                        src={CreateClientImage} // Use the imported image
                                        alt="Create New Client"
                                        className="create-client"
                                    />
                                    <h3 className="client-heading">Create New Client</h3>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="create-client-form">
                            <h2>Create New Client</h2>
                            <Form
                                onFinish={onFinish}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 14 }}
                                layout="horizontal"
                                style={{ maxWidth: "600px", margin: "0 auto" }}
                            >
                                {/* Client Image */}
                                <Form.Item
                                    label="Client Image"
                                    name="clientImage"
                                    valuePropName="file"
                                >
                                    <Upload
                                        name="clientImage"
                                        showUploadList={false}
                                        customRequest={handleImageChange}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload Client Image</Button>
                                    </Upload>
                                </Form.Item>

                                {/* Client Name */}
                                <Form.Item
                                    label="Client Name"
                                    name="clientName"
                                    rules={[{ required: true, message: "Please input the client name!" }]}
                                >
                                    <Input />
                                </Form.Item>

                                {/* Role Name */}
                                <Form.Item
                                    label="Role Name"
                                    name="roleName"
                                    rules={[{ required: true, message: "Please input the role name!" }]}
                                >
                                    <Input />
                                </Form.Item>

                                {/* Office Address 1 */}
                                <Form.Item
                                    label="Office Address 1"
                                    name="address1"
                                    rules={[{ required: true, message: "Please input the office address!" }]}
                                >
                                    <Input />
                                </Form.Item>

                                {/* Office Address 2 */}
                                <Form.Item label="Office Address 2" name="address2">
                                    <Input />
                                </Form.Item>

                                {/* POC Name */}
                                <Form.Item
                                    label="POC Name"
                                    name="pocName"
                                    rules={[{ required: true, message: "Please input the POC name!" }]}
                                >
                                    <Input />
                                </Form.Item>

                                {/* POC Contact Number */}
                                <Form.Item
                                    label="POC Contact"
                                    name="pocContact"
                                    rules={[{ required: true, message: "Please input the POC contact!" }]}
                                >
                                    <Input />
                                </Form.Item>

                                {/* POC Email */}
                                <Form.Item
                                    label="POC Email"
                                    name="pocEmail"
                                    rules={[
                                        { required: true, message: "Please input the POC email!" },
                                        { type: "email", message: "Please enter a valid email!" },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                {/* Buttons */}
                                <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                                    <Button
                                        type="default"
                                        onClick={() => setIsCreating(false)}
                                        style={{ marginRight: "10px" }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Create Client
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    )}
                </div>
            </Content>
        </Layout>
    );
};

export default CreateClient;
