import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useBreadcrumb } from "./breadcrumbContext";
import { Breadcrumb } from "antd";

const DynamicBreadcrumb = () => {
    const location = useLocation();
    const { resetBreadcrumb, appendBreadcrumb } = useBreadcrumb();

    const pathnames = location.pathname.split('/').filter(Boolean);

    // Use a ref to track previous pathnames
    const prevPathnamesRef = useRef();

    useEffect(() => {
        // If the pathnames have changed, merge them into the breadcrumb context
        const newPath = pathnames.join('/');
        if (prevPathnamesRef.current !== newPath) {
            appendBreadcrumb({ name: pathnames[pathnames.length - 1], path: `/${newPath}` });
            prevPathnamesRef.current = newPath;
        }
    }, [location.pathname, pathnames, appendBreadcrumb]);

    return (
        <Breadcrumb style={{ marginTop: '9%', marginLeft: '20px' }}>
            <Breadcrumb.Item>
                <Link to="/">Home</Link>
            </Breadcrumb.Item>
            {pathnames.map((value, index) => {
                const url = `/${pathnames.slice(0, index + 1).join('/')}`;
                const label = value.charAt(0).toUpperCase() + value.slice(1);
                return (
                    <Breadcrumb.Item key={url}>
                        <Link to={url}>{label}</Link>
                    </Breadcrumb.Item>
                );
            })}
        </Breadcrumb>
    );
};

export default DynamicBreadcrumb;
