import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store/store'; // Ensure store is imported properly
import Home from "./components/Home";
import LoginPage from "./components/LoginPage";
import ForgotPassword from "./components/forgotPassword";
import ResetPassword from "./components/resetPassword";
import { removeToken } from './services/LocalStorageService';
import { unSetUserToken } from './features/authSlice';
import { onError } from '@apollo/client/link/error';
import { unSetUserAccount } from "./features/authSlice";
import { useNavigate } from 'react-router-dom';
import Sidebar from "./sidebar/sidebar";
import { Layout } from "antd";
import ReusableHeader from "./reusableField/ReusableHeader";
import CreateClient from "./components/createClient";
import CreateClientForm from "./components/createClientForm";
import ImportFromLense from "./components/importFromLense";
import DynamicBreadcrumb from './components/dynamicBreadcrumb';
import { BreadcrumbProvider } from "./components/breadcrumbContext";
import ConfirmPassword from "./components/confirmPassword";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { access_token } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();  // Move useNavigate here after Router is available

    // window.onbeforeunload = function () {
    //     localStorage.clear();
    // };

    useEffect(() => {
        // Check if the user is authenticated based on the access_token
        if (access_token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [access_token]);


    const httpLink = createHttpLink({
        uri: 'https://clanapilightsail.clanonline.in/clantestapi/graphql',
    });

    const errorLink = onError(({ networkError }) => {
        if (networkError && networkError.statusCode === 401) handleLogout();
    });

    const authLink = setContext((_, { headers }) => {
        const token = access_token;
        return {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('flutter.accessToken')}`,  // Get token from localStorage
            },
        };
    });

    const links = ApolloLink.from([
        errorLink,
        authLink,
        httpLink,
    ]);

    const client = new ApolloClient({
        link: links,
        cache: new InMemoryCache(),
    });

    client.defaultOptions = {
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
    };

    // Handle Logout
    const handleLogout = () => {
        removeToken();
        dispatch(unSetUserToken({ access_token: null }));
        dispatch(unSetUserAccount({ user_account: null }));
        navigate("/login-page");
        // window.location.href('/login-page');  // Use navigate here
        // window.location.reload();
    };

    return (
        <>
            <BreadcrumbProvider>
                <Provider store={store}>
                    <ApolloProvider client={client}>
                        {isAuthenticated}
                        <Routes>
                            <Route path="/login-page" element={isAuthenticated ? (<Navigate to="/home" replace />) : (<LoginPage setIsAuthenticated={setIsAuthenticated} />)} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/confirmPassword" element={<ConfirmPassword />} />
                            <Route path="/home" element={isAuthenticated ? (<Layout style={{ minHeight: "100vh" }}>
                                <Sidebar />
                                <div style={{ flex: 1 }}>
                                    <ReusableHeader setIsAuthenticated={setIsAuthenticated} />
                                    <DynamicBreadcrumb />
                                    <Home />
                                </div>
                            </Layout>
                            ) : (<Navigate to="/login-page" replace />)}
                            />
                            <Route
                                path="/create-client"
                                element={
                                    isAuthenticated ? (
                                        <Layout style={{ minHeight: "100vh" }}>
                                            <Sidebar />
                                            <div style={{ flex: 1 }}>
                                                <ReusableHeader setIsAuthenticated={setIsAuthenticated} />
                                                <DynamicBreadcrumb />
                                                <CreateClient />
                                            </div>
                                        </Layout>
                                    ) : (
                                        <Navigate to="/login-page" replace />
                                    )
                                }
                            />
                            <Route
                                path="/create-client-form"
                                element={
                                    isAuthenticated ? (
                                        <Layout style={{ minHeight: "100vh" }}>
                                            <Sidebar />
                                            <div style={{ flex: 1 }}>
                                                <ReusableHeader setIsAuthenticated={setIsAuthenticated} />
                                                <DynamicBreadcrumb />
                                                <CreateClientForm />
                                            </div>
                                        </Layout>
                                    ) : (
                                        <Navigate to="/login-page" replace />
                                    )
                                }
                            />
                            <Route
                                path="/import-from-lense"
                                element={
                                    isAuthenticated ? (
                                        <Layout style={{ minHeight: "100vh" }}>
                                            <Sidebar />
                                            <div style={{ flex: 1 }}>
                                                <ReusableHeader setIsAuthenticated={setIsAuthenticated} />
                                                <DynamicBreadcrumb />
                                                <ImportFromLense />
                                            </div>
                                        </Layout>
                                    ) : (
                                        <Navigate to="/login-page" replace />
                                    )
                                }
                            />

                            <Route path="/" element={<Navigate to={isAuthenticated ? "/home" : "/login-page"} replace />} />

                        </Routes>
                    </ApolloProvider>
                </Provider>
            </BreadcrumbProvider>
        </>
    );
}

export default App;
