import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.css"; // Use custom styles here
import Arrow from "../Images/arrow_sidebar.png"
import Home from "../Images/home.png"
import HomeOutline from "../Images/homeOuline.png"
import CreateFolder from "../Images/create_new_folder.png"
import CreateFolderOutline from "../Images/create_new_folder_outline.png"
import { useBreadcrumb } from "../components/breadcrumbContext";
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout;

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const { resetBreadcrumb } = useBreadcrumb();
    const navigate = useNavigate();
    const menuKeyMap = {
        "/home": "1",
        "/create": "2",
        "/global": "3",
    };

    const handleMenuClick = (path) => {
        resetBreadcrumb(); // Reset breadcrumb to root
        navigate(path); // Navigate to the clicked path
    };

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            {/* Sidebar */}
            <Sider
                // collapsible
                collapsed={collapsed}
                onCollapse={() => setCollapsed(!collapsed)}
                // width={250} // Sidebar width
                style={{
                    height: "100vh",
                    position: "fixed", // Sidebar remains fixed
                    left: 0,
                    background: "#fff",
                    borderRight: "1px solid #f0f0f0",
                    zIndex: 10,
                    top: "17%",
                    width: "0%"
                }}
            >
                {/* Collapse button */}
                <div
                    style={{
                        padding: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: collapsed ? "center" : "center",
                    }}
                >
                    <button
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            background: "transparent",
                            border: "none",
                            fontSize: "16px",
                            cursor: "pointer",
                        }}
                    >
                        <img
                            src={Arrow}
                            alt="Collapse Arrow"
                            style={{
                                transform: collapsed ? "rotate(180deg)" : "rotate(0deg)",
                                transition: "transform 0.3s ease",
                                width: "20px",
                                height: "20px",
                                marginTop: "28%",
                            }}
                        />
                    </button>
                    {!collapsed && <span style={{ color: "#888888", fontWeight: "700", display: "flex", alignItems: "center", fontSize: "16px" }}>Menu</span>}
                </div>

                {/* Menu Items */}
                <Menu
                    mode="inline"
                    selectedKeys={[menuKeyMap[location.pathname]]} // Dynamically highlight the menu item
                    style={{
                        // marginTop: "16px",
                        border: "none",
                        background: "#fff",
                    }}
                >
                    <Menu.Item
                        key="1"
                        onClick={() => handleMenuClick('/home')}
                        icon={
                            <img
                                src={location.pathname === "/home" ? Home : HomeOutline}
                                alt="Home"
                                style={{
                                    width: "20px", // Set icon width
                                    height: "20px", // Set icon height
                                }}
                            />
                        }
                    >
                        <Link to="/home" style={{ fontWeight: "500", color: "#000" }}>
                            Home
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="2"
                        onClick={() => handleMenuClick('/create-client')}
                        icon={
                            <img
                                src={location.pathname === "/create-client" ? CreateFolder : CreateFolderOutline}
                                alt="Home"
                                style={{
                                    width: "20px", // Set icon width
                                    height: "20px", // Set icon height
                                }}
                            />
                        }
                    >
                        <Link to="/create-client" style={{ color: "#000" }}>
                            Create
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="3"
                        onClick={() => handleMenuClick('/confirmPassword')}
                        icon={
                            <img
                                src={location.pathname === "/confirmPassword" ? CreateFolder : CreateFolderOutline}
                                alt="Home"
                                style={{
                                    width: "20px", // Set icon width
                                    height: "20px", // Set icon height
                                }}
                            />
                        }
                    >
                        <Link to="/confirmPassword" style={{ color: "#000" }}>
                            Global Initialization
                        </Link>
                    </Menu.Item>
                </Menu>


                {/* Footer */}
                <div
                    style={{
                        position: "absolute",
                        bottom: "16px",
                        width: "100%",
                        textAlign: "center",
                        color: "#888",
                        fontSize: "12px",
                    }}
                >
                    <span>powered by TMI Group</span>
                </div>
            </Sider>

            {/* Main Content */}
            <div
                style={{
                    marginLeft: collapsed ? 30 : 150, // Adjust width based on collapse state
                    padding: "24px",
                    width: "100%",
                    transition: "margin-left 0.2s ease", // Smooth transition for content shift
                }}
            >
                {/* <h2>Main Content</h2> */}
                {/* Replace this with your cards or any other content */}
            </div>
        </div>
    );
};

export default Sidebar;
