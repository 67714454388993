import React, { useState, useEffect } from "react";
import { Layout, message } from "antd";
import { useQuery } from "@apollo/client";
import { Get_Lense_Account, Get_Lense_Account_Projects } from "../services/userAPI";
import { useNavigate } from "react-router-dom";
import "./createClient.css";
const { Content } = Layout;

const ImportFromLense = () => {
    const navigate = useNavigate();
    const [accountId, setAccountId] = useState(null); // State to hold the selected account ID
    const { loading, error, data } = useQuery(Get_Lense_Account);

    // Trigger project query only when accountId is set
    const {
        data: projectsData,
        loading: projectsLoading,
        error: projectsError,
    } = useQuery(Get_Lense_Account_Projects, {
        variables: { clientId: accountId }, // Fetch projects based on selected account
        skip: !accountId, // Only run the query if accountId is set
    });
    console.log(accountId);

    useEffect(() => {
        if (projectsData) {
            console.log("Fetched Projects Data:", projectsData);
            // You can now log or check the data here for matches
            if (projectsData.getLenseAccountProjects) {
                // Check if the accountId matches any of the returned projects
                const matchedProjects = projectsData.getLenseAccountProjects.filter(
                    (project) => project.clientId === accountId
                );
                console.log("Matched Projects for Account ID", accountId, matchedProjects);
            }
        }
    }, [projectsData, accountId]);

    // Log projectsData for debugging
    useEffect(() => {
        console.log("Projects Data:", projectsData);
    }, [projectsData]);

    // Handle error for loading projects
    useEffect(() => {
        if (projectsError) {
            message.error(`Failed to load projects: ${projectsError.message}`);
        }
    }, [projectsError]);

    // Set accountId and trigger project fetching
    const handleEdit = (id, accountId, name, address, imageUrl, projectsData, role) => {
        setAccountId(id);
        navigate(`/create-client-form?id=${id}`, {
            state: { name, accountId, address, imageUrl, projectsData, role },
        });
    };

    // Render loading states for accounts and projects
    if (loading) return <div>Loading Lense Accounts...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <Layout>
            <Content style={{ margin: "0 16px", marginTop: "12px" }}>
                <div className="create-client-container">
                    <h2>Lense Clients</h2>
                    <div className="cards-container">
                        {data.getLenseAccount?.map((account) => (
                            <div key={account.id} className="card">
                                <img
                                    src={account.imageUrl || "https://via.placeholder.com/80"}
                                    alt={account.name}
                                    className="card-image"
                                />
                                <h3 className="card-title">{account.name}</h3>
                                {/* <p>{account.address}</p> */}
                                <p> {account.role}</p> {/* Displaying role */}
                                <div className="card-buttons">
                                    <button
                                        className="card-button"
                                        onClick={() =>
                                            handleEdit(account.id, account.accountId, account.name, account.address, account.imageUrl, projectsData, account.role)
                                        }
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Render loading state for projects */}
                    {projectsData && projectsData.getLenseAccountProjects?.length > 0 ? (
                        projectsData.getLenseAccountProjects.map((project) => (
                            <div key={project.id}>
                                <h4>{project.name}</h4>
                                <p>ID: {project.id}</p>
                            </div>
                        ))
                    ) : (
                        <div>No projects available.</div>
                    )}
                </div>
            </Content>
        </Layout>
    );
};

export default ImportFromLense;
