import React from "react";
import { Layout, Spin } from "antd";
import { useQuery } from "@apollo/client";
import { Accounts_Query } from "../services/userAPI";
import ReusableHeader from "../reusableField/ReusableHeader";
import "./Home.css";

const { Content } = Layout;

const Home = () => {
    const { loading, error, data } = useQuery(Accounts_Query);

    if (loading) return
    if (error) return <div>Error: {error.message}</div>;

    return (
        <Layout>
            <ReusableHeader />
            <Content style={{ margin: "0 16px", marginTop: "0px" }}>
                <div className="home-content">
                    <div className="heading">Active Clients</div>
                    <div className="cards-container">
                        {data.allUserAccount.map((user) => (
                            <div key={user.id} className="card">
                                <img
                                    src={user.mainLogoUrl || "https://via.placeholder.com/80"}  // Fallback if no logo is available
                                    alt={user.name}
                                    className="card-image"
                                />
                                <h3 className="card-title">{user.name}</h3>
                                <button className="card-button">Edit</button>
                            </div>
                        ))}
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default Home;
